<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">基本信息</div>
      <div class="back" @click="goback">返回上一页</div>
    </div>
    <div class="from-box">
      <div class="line-box">
        <div class="line-title">员工编码</div>
        <el-input v-model="params.UserID" placeholder="员工编码" />
      </div>
      <div class="line-box">
        <div class="line-title">中文名</div>
        <el-input v-model="params.UserName" placeholder="中文名" />
      </div>
      <div class="line-box">
        <div class="line-title">拼音姓名</div>
        <el-input v-model="params.UserName_EN" placeholder="拼音姓名" />
      </div>
      <div class="line-box">
        <div class="line-title">国籍</div>
        <el-select v-model="params.Country" placeholder="请选择">
          <el-option
            v-for="item in Countries"
            :key="item.ID"
            :label="item.CountryNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">性别</div>
        <el-select v-model="params.Sex" placeholder="请选择">
          <el-option
            v-for="item in Genders"
            :key="item.ID"
            :label="item.GenderName"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">民族</div>
        <el-select v-model="params.Nationality" placeholder="请选择">
          <el-option
            v-for="item in Nationalities"
            :key="item.ID"
            :label="item.NationalityNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">身份证号</div>
        <el-input v-model="params.IDNumber" placeholder="拼音姓名" />
      </div>
      <div class="line-box">
        <div class="line-title">出生日期</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.BirthDay"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">最高学历</div>
        <el-select v-model="params.Education" placeholder="请选择">
          <el-option
            v-for="item in Educations"
            :key="item.ID"
            :label="item.EduNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">最高学历获取时间</div>
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="params.HighEduTime"
          type="date"
          placeholder="选择日期"
        >
        </el-date-picker>
      </div>
      <div class="line-box">
        <div class="line-title">毕业院校</div>
        <el-select v-model="params.School" placeholder="请选择">
          <el-option
            v-for="item in Schools"
            :key="item.ID"
            :label="item.SchoolNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">所学专业</div>
        <el-select v-model="params.Specialities" placeholder="请选择">
          <el-option
            v-for="item in Specialities"
            :key="item.ID"
            :label="item.SpecialitiesNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">政治面貌</div>
        <el-select v-model="params.Politics" placeholder="请选择">
          <el-option
            v-for="item in Politics"
            :key="item.ID"
            :label="item.PoliticsNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">籍贯</div>
        <el-input v-model="params.Province" placeholder="籍贯" />
      </div>
      <div class="line-box">
        <div class="line-title">户口所在地</div>
        <el-input v-model="params.City" placeholder="户口所在地" />
      </div>
      <div class="line-box">
        <div class="line-title">婚姻状态</div>
        <el-select v-model="params.Marriage" placeholder="请选择">
          <el-option
            v-for="item in Marriages"
            :key="item.ID"
            :label="item.MarriageNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">宗教信仰</div>
        <el-select v-model="params.Religion" placeholder="请选择">
          <el-option
            v-for="item in Religions"
            :key="item.ID"
            :label="item.ReligionCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box">
        <div class="line-title">血型</div>
        <el-select v-model="params.BloodType" placeholder="请选择">
          <el-option
            v-for="item in Bloods"
            :key="item._id"
            :label="item._bloodnamechs"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box line-upload">
        <div class="line-title">员工照片</div>
        <div class="upload-load">
          <div class="left">
            <div class="name">
              <svg-icon class="icon" icon-class="glyphicon-file"></svg-icon>
              <span @click="goherf(params.Column_34)">{{
                params.Column_34
              }}</span>
            </div>
          </div>
          <el-upload
            action="#"
            class="card-upload"
            :auto-upload="true"
            :http-request="uploadAttachment"
            ref="uploadRef"
          >
            <button class="btn-up disabled">
              <svg-icon class="icon" icon-class="file"></svg-icon>选择 ...
            </button>
          </el-upload>
        </div>
      </div>
      <div class="line-box">
        <div class="line-title">过往病史</div>
        <el-input v-model="params.MedicalHistory" placeholder="过往病史" />
      </div>
      <div class="line-box">
        <div class="line-title">过往工伤信息</div>
        <el-input v-model="params.WorkersTrouble" placeholder="过往工伤信息" />
      </div>
      <div class="line-box">
        <div class="line-title">常住地址</div>
        <el-input v-model="params.Address" placeholder="常住地址" />
      </div>
      <div class="line-box">
        <div class="line-title">员工母国电话号码</div>
        <el-input v-model="params.Phone" placeholder="员工母国电话号码" />
      </div>
      <div class="line-box">
        <div class="line-title">工作国家电话号码</div>
        <el-input v-model="params.FixedPhones" placeholder="工作国家电话号码" />
      </div>
      <div class="line-box">
        <div class="line-title">个人邮箱</div>
        <el-input v-model="params.Mail" placeholder="个人邮箱" />
      </div>
      <div class="line-box">
        <div class="line-title">社交软件</div>
        <el-input v-model="params.QQ" placeholder="社交软件" />
      </div>
      <div class="line-box">
        <div class="line-title">工作服尺寸</div>
        <el-input v-model="params.UniformSize" placeholder="工作服尺寸" />
      </div>
      <div class="line-box">
        <div class="line-title">劳保鞋尺寸</div>
        <el-input v-model="params.ShoeSize" placeholder="劳保鞋尺寸" />
      </div>
      <div class="line-box">
        <div class="line-title">家庭成员及社会关系姓名1</div>
        <el-input
          v-model="params.FamilyMembers1"
          placeholder="家庭成员及社会关系姓名1"
        />
      </div>
      <div class="line-box">
        <div class="line-title">与本人的关系1</div>
        <el-input v-model="params.WithRelation1" placeholder="与本人的关系1" />
      </div>
      <div class="line-box">
        <div class="line-title">家庭成员联系方式1</div>
        <el-input
          v-model="params.FamilyMembersContact1"
          placeholder="家庭成员联系方式1"
        />
      </div>
      <div class="line-box">
        <div class="line-title">家庭成员及社会关系姓名2</div>
        <el-input
          v-model="params.FamilyMembers2"
          placeholder="家庭成员及社会关系姓名2"
        />
      </div>
      <div class="line-box">
        <div class="line-title">与本人的关系2</div>
        <el-input v-model="params.WithRelation2" placeholder="与本人的关系1" />
      </div>
      <div class="line-box">
        <div class="line-title">家庭成员联系方式2</div>
        <el-input
          v-model="params.FamilyMembersContact2"
          placeholder="家庭成员联系方式2"
        />
      </div>
      <div class="line-box">
        <div class="line-title">家庭成员及社会关系姓名3</div>
        <el-input
          v-model="params.FamilyMembers3"
          placeholder="家庭成员及社会关系姓名3"
        />
      </div>
      <div class="line-box">
        <div class="line-title">与本人的关系3</div>
        <el-input v-model="params.WithRelation3" placeholder="与本人的关系3" />
      </div>
      <div class="line-box">
        <div class="line-title">家庭成员联系方式3</div>
        <el-input
          v-model="params.FamilyMembersContact3"
          placeholder="家庭成员联系方式3"
        />
      </div>
      <div class="line-box line-box2">
        <div class="line-title">第二语种</div>
        <el-select v-model="params.Language1" placeholder="请选择">
          <el-option
            v-for="item in Languages"
            :key="item.ID"
            :label="item.LanguageNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box line-box2">
        <div class="line-title">第二语种水平</div>
        <el-input v-model="params.LanguageLevel1" placeholder="第二语种水平" />
      </div>
      <div class="line-box line-box2">
        <div class="line-title">掌握语种2</div>
        <el-select v-model="params.Language2" placeholder="请选择">
          <el-option
            v-for="item in Languages"
            :key="item.ID"
            :label="item.LanguageNameCHS"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </div>
      <div class="line-box line-box2">
        <div class="line-title">语种水平2</div>
        <el-input v-model="params.LanguageLevel2" placeholder="语种水平2" />
      </div>
      <div class="buttons">
        <el-button class="pop-close" @click="goback">取消</el-button
        ><el-button class="pop-save" @click="updateGeneral">保存</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getGeneral,
  getGeneralBasic,
  updateGeneral,
  photoUpload,
} from "@/api/other";
export default {
  data() {
    return {
      params: {
        ID: null,
        UserID: null,
        UserName: null,
        UserName_EN: null,
        Sex: null,
        Country: null,
        Nationality: null,
        IDNumber: null,
        BirthDay: null,
        Education: null,
        School: null,
        HighEduTime: null,
        Specialities: null,
        Politics: null,
        Province: null,
        City: null,
        Address: null,
        Phone: null,
        FixedPhones: null,
        Mail: null,
        QQ: null,
        Marriage: null,
        BloodType: null,
        MedicalHistory: null,
        WorkersTrouble: null,
        Column_34: null,
        Religion: null,
        Language1: null,
        LanguageLevel1: null,
        Language2: null,
        LanguageLevel2: null,
        FamilyMembers1: null,
        WithRelation1: null,
        FamilyMembersContact1: null,
        FamilyMembers2: null,
        WithRelation2: null,
        FamilyMembersContact2: null,
        FamilyMembers3: null,
        WithRelation3: null,
        FamilyMembersContact3: null,
        UniformSize: null,
        ShoeSize: null,
      },

      Countries: [], //国籍
      Genders: [], //性别
      Nationalities: [], //民族
      Educations: [], //最高学历
      Schools: [], //毕业院校
      Specialities: [], //高校专业
      Politics: [], //政治面貌
      Marriages: [], //婚姻状况
      Religions: [], //宗教信仰
      Bloods: [], //血型
      Languages: [], //语种
    };
  },
  methods: {
    goback() {
      this.$router.push("/ordinary");
    },
    uploadAttachment(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      if (!suffix && !suffix2 && !suffix3) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        // 事件的result属性包含了文件的Base64表示
        const base64 = event.target.result;
        console.log("base64", base64);

        photoUpload({ Source: base64 }).then((res) => {
          if (res.status === 200) {
            this.params.Column_34 =
              "https://hrms.baishulai.com/upload/photo/" + res.response;
          }
        });

        // 在这里可以继续使用base64字符串，例如显示图片等
      };
      reader.readAsDataURL(file.file);
    },
    getGeneral() {
      getGeneral().then((res) => {
        if (res.status === 200) {
          this.params = res.response;
        }
      });
    },
    getGeneralBasic() {
      getGeneralBasic().then((res) => {
        if (res.status === 200) {
          const data = res.response;
          this.Countries = data.Countries;
          this.Genders = data.Genders;
          this.Nationalities = data.Nationalities;
          this.Educations = data.Educations;
          this.Schools = data.Schools;
          this.Specialities = data.Specialities;
          this.Politics = data.Politics;
          this.Marriages = data.Marriages;
          this.Religions = data.Religions;
          this.Bloods = data.Bloods;
          this.Languages = data.Languages;
        }
      });
    },
    updateGeneral() {
      updateGeneral(this.params).then((res) => {
        if (res.status === 200) {
          this.$message.success("修改成功");
        }
      });
    },
    goherf(href) {
      location.href = href;
    },
  },

  created() {
    this.getGeneral();
    this.getGeneralBasic();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .back {
      margin-left: 24px;
      color: #257eff;
      cursor: pointer;
    }
  }
  .from-box {
    width: 100%;
    min-height: calc(100% - 40px);
    background-color: #fff;
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    padding-top: 20px;
    box-sizing: border-box;
    justify-content: space-between;
    align-content: flex-start;
    .line-box {
      width: 30%;
      margin-bottom: 24px;
      .line-title {
        font-size: 14px;
        color: #100303;
        margin-bottom: 10px;
      }
      .el-select {
        width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
    }
    .line-box2 {
      width: 24%;
    }
    .line-upload {
      width: 100%;
    }
    .buttons {
      width: 100%;

      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .upload-load {
      display: flex;
      height: 34px;
      //   margin-top: 24px;
      .left {
        width: 100%;
        border: 1px solid #d2d6de;
        border-right: none;
        .name {
          display: flex;
          align-items: center;
          height: 34px;
          color: #555;
          font-size: 14px;
          text-indent: 2px;
          span {
            cursor: pointer;
            color: #257eff;
          }
        }
      }
      .btn-up {
        padding: 0;
        margin: 0;
        border: none;
        width: 90px;
        flex-shrink: 0;
        height: 34px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: #3c8dbc;
        border: 1px solid #367fa9;
        color: #fff;
        font-size: 14px;
        .icon {
          margin-right: 5px;
        }
      }
      .disabled {
        cursor: no-drop;
      }
      .btn-up:hover {
        border-color: #204d74;
        background-color: #367fa9;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 1px solid #f4f4f4;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}
.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}
.tableData2 >>> .el-table__header .cell {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.tableData2 >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
.card-upload >>> .el-upload-list {
  display: none;
}
.dialog >>> .el-dialog__body {
  padding: 15px 15px 0 15px;
}
.card-select >>> .el-input__icon {
  line-height: 34px;
}
</style>
